import { Events } from './events-catalog';

export * from './events-catalog';

const hash = Date.now().toString(36).substring(2);

export class events {
  static _identifier = hash;

  static _logger: BroadcastChannel | null = null;

  static _lastEvent: { [key: string]: { payload: any } } = {};

  static on<K extends keyof Events>(
    event: K,
    handler: (payload: Events[K]) => void,
    options?: { eager?: boolean }
  ) {
    if (typeof BroadcastChannel === 'undefined') {
      return;
    }

    const subscriber = new BroadcastChannel('ElsieSDK/EventBus');

    if (options?.eager) {
      const lastEvent = this._lastEvent[event];

      if (lastEvent) {
        handler(lastEvent.payload);
      }
    }

    subscriber.addEventListener('message', ({ data }) => {
      // ignore events from other instances (only if identifier is set)
      if (this._identifier && this._identifier !== data.identifier) return;

      if (data.event === event) {
        handler(data.payload);
      }
    });

    // unsubscribe if leaving page
    window.addEventListener('beforeunload', () => {
      subscriber.close();
    });

    return {
      off() {
        subscriber.close();
      },
    };
  }

  static emit<K extends keyof Events>(event: K, payload: Events[K]) {
    if (typeof BroadcastChannel === 'undefined') {
      return;
    }

    const publisher = new BroadcastChannel('ElsieSDK/EventBus');

    publisher.postMessage({ event, identifier: this._identifier, payload });

    this._lastEvent[event] = {
      payload,
    };

    publisher.close();
  }

  static enableLogger(enabled: boolean) {
    if (typeof BroadcastChannel === 'undefined') {
      return;
    }

    // reset logger
    this._logger?.close();
    this._logger = null;

    if (enabled === false) return;

    // create new logger
    this._logger = new BroadcastChannel('ElsieSDK/EventBus');

    this._logger.addEventListener('message', ({ data }) => {
      if (this._identifier && this._identifier !== data.identifier) return;
      console.group(`📡 Event (${data.identifier}) ➡ ${data.event}`);
      console.log(data.payload);
      console.groupEnd();
    });


    // unsubscribe if leaving page
    window.addEventListener('beforeunload', () => {
      this._logger?.close();
    });
  }
}
